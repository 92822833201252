import { mutate } from 'swr';
import { fetchApiHub } from '../../lib/fetch-api-hub';

export const getTrustbadge = async (type?: string) => {
  const response = await fetchApiHub('/action/trustedshops/getTrustbadge', { method: 'POST' }, { type });
  const trustedbadge =
    response.trustbadge?.trustedShopsId || response.trustedShopsId ? response.trustbadge ?? response : null;

  await mutate('/action/trustedshops/getTrustbadge', trustedbadge);
  return trustedbadge;
};
