const screenSizes = {
  smallMobile: 320,
  mobile: 468,
  smallTablet: 649,
  tablet: 768,
  desktop: 1024,
  mediumDesktop: 1280,
  largeDesktop: 1440,
  hugeDesktop: 1666,
};

export const { smallMobile, mobile, smallTablet, tablet, desktop, mediumDesktop, largeDesktop, hugeDesktop } =
  screenSizes;
