export type URLParam = {
  key: string;
  value: string;
};

type updateURLParamsProps = (
  params: URLParam[],
  useSearch?: boolean,
  replaceKeys?: string[],
  baseUrl?: string,
) => string;

export const updateURLParams: updateURLParamsProps = (
  params,
  useSearch = false,
  replaceKeys = [],
  baseUrl?: string,
) => {
  const url = baseUrl ?? new URL(window.location.href).pathname;
  const searchURLParam = new URLSearchParams(window.location.search).get('query');
  const nextURLParams = new URLSearchParams(useSearch ? window.location.search : undefined);

  if (searchURLParam) {
    nextURLParams.set('query', searchURLParam);
  }

  params.forEach(({ key, value }) => {
    if (replaceKeys.includes(key)) {
      nextURLParams.set(key, value);
    } else {
      nextURLParams.append(key, value);
    }
  });

  const paramString = nextURLParams.toString();
  return `${url}${paramString.length > 0 ? '?' : ''}${paramString}`;
};
